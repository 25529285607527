.overlay {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
}

.loader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 999;
}
.loader .dot {
  animation: slide 1s infinite;
  background: #0e7cc5;
  border-radius: 100%;
  display: inline-block;
  height: 24px;
  width: 24px;
}
.loader .dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #5dade0;
}
.loader .dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #1a2534;
}
.loader .dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #2e3d50;
}
.loader .dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #3c4e62;
}
.loader .dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #778391;
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}